import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import {
  mapEdgesToNodes,
} from "lib/helpers";
import Project from "modules/Project/project";
import WorkCatMenu from "modules/Header/WorkCatMenu";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import VideoGrid from "modules/VideoGrid/VideoGrid";
import CategoryFilter from "modules/CategoryFilter/CategoryFilter";
export const query = graphql`
  query WorkTemplateQuery($idRegex: String = "//") {
    projects: allSanityProject(filter: {category: {label: {regex: $idRegex}}}, sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          title
          subtitle
          thumbnail {
            image {
              alt
              asset {
                gatsbyImage(width: 800)
              }
            }
            video
            mobileVideo
          }
          slug {
            current
          }
          category {
            label
            value
          }
          mainVideo
          tags {
            label
            value
          }
          id
          accentColor
        }
      }
    }
  }
`;

const WorkTemplate = props => {
  const [ Filter, setFilter ] = React.useState();
  const { data, errors, pageContext } = props;
  const projects = (data || {}).projects
  ? mapEdgesToNodes(data.projects)
  : [];

  const filteredProjects = projects.filter((project) => { 
    if(!Filter)
      return true;

    return project.tags.find(element => element.label === Filter);
  })

  console.log(filteredProjects)


  const tags = pageContext.tags;
  const activeLabel = pageContext.activeLabel;
  const title = pageContext.seoTitle;

  return (
    <Layout workHeader activeItem="work" activeCat={activeLabel}>
      <SEO
        title={title}
        />
      {errors && <SEO title="GraphQL Error" />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <WorkCatMenu activeCat={activeLabel}/>
      <CategoryFilter total={projects.length} filteredTotal={filteredProjects.length} tags={tags} setFilter={setFilter} filter={Filter}/>
      <VideoGrid nodes={filteredProjects}/>
    </Layout>
  );
};

export default WorkTemplate;
